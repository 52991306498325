<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import {
    required,
    email,
    helpers
  } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: "Starter",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
        mail:"",
        user: "",
        password: "",
        architecture:"",
        submitted: false,
        authError: null,
        isAuthError: false,
      
      title: "Starter",
      items: [
        {
          text: "Pages",
          href: "/",
        },
        {
          text: "Starter",
          active: true,
        },
      ],
    };
  },
  validations: {
      user: {
        required: helpers.withMessage("Se requiere el correo", required),
      },
      mail: {
        required: helpers.withMessage("correo requeridad", required),
        email: helpers.withMessage("correo incorrecto", email),
      }
    },
  components: {
    Layout,
    PageHeader,
  },
  methods: {
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      } else {
        alert("Please enter");
      }
    },
  }    
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
  
  <div class="p-2 mt-4">
    <form @submit.prevent="tryToLogIn">
      <div class="mb-4">
        <label class="form-label">Usuario</label>
        <input
          type="text"
          v-model="user"
          class="form-control"
          id="user"
          :class= "[submitted && Object.keys( v$.user.$errors).length === 0 ? 'is-valid': 'is-invalid' ] "
          placeholder="usuario"
        />
        <div
          v-for="(item, index) in v$.user.$errors"
          :key="index"
          class="invalid-feedback"
        >
          <span v-if="item.$message">{{ item.$message }}</span>
        </div>
      </div>
      <div class="mb-4">
        <label class="form-label">Correo Electrónico</label>
        <input
          type="text"
          v-model="mail"
          class="form-control"
          id="mail"
          :class="{ 'is-invalid': submitted && v$.mail.$errors }"
          placeholder="correo"
        />
        <div
          v-for="(item, index) in v$.mail.$errors"
          :key="index"
          class="invalid-feedback"
        >
          <span v-if="item.$message">{{ item.$message }}</span>
        </div>
        {{  v$.mail.$errors}}
      </div>
      <div class="text-center mt-4">
        <button class="btn btn-success w-100" type="submit">
          Recibir Clave Temporal
        </button>
      </div>
    </form>
  </div></Layout>
</template>